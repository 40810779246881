export const SECOND = 1;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

export function parseSeconds(seconds: number) {
  let days = 0,
    hours = 0,
    minutes = 0,
    remainder = seconds;

  if (remainder >= DAY) {
    days = Math.floor(remainder / DAY);
    remainder %= DAY;
  }

  if (remainder >= HOUR) {
    hours = Math.floor(remainder / HOUR);
    remainder %= HOUR;
  }

  if (remainder >= MINUTE) {
    minutes = Math.floor(remainder / MINUTE);
    remainder %= MINUTE;
  }

  return { days, hours, minutes, seconds: Math.floor(remainder) };
}

function padNumber(n: number, places: number) {
  return n.toString().padStart(places, '0');
}

export function formatDuration(seconds: number) {
  const parsed = parseSeconds(seconds);

  // An undefined value here means the value will be omitted from the resulting timestamp.
  return [
    // Only display days if > 0
    parsed.days || undefined,
    // Only display hours if > 0 or days > 0
    parsed.days > 0 ? parsed.hours : parsed.hours || undefined,
    // Always display minutes
    parsed.minutes,
    // Always display seconds
    parsed.seconds,
  ]
    .filter(n => n != null)
    .map((n, idx) => padNumber(n, idx === 0 ? 1 : 2))
    .join(':');
}
