import { type IconProps, Icon } from './icon.js';

export function Shuffle(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Shuffle" {...props}>
      <svg viewBox="0 0 24 24">
        <path d="m17.95 10.64 2.28-2.29a.5.5 0 0 0 0-.7l-2.28-2.28a.5.5 0 0 0-.86.35v1.33c-1.46.16-4 .99-6.3 4.42a7.75 7.75 0 0 1-6.16 3.56 1 1 0 1 0 0 2c1.17 0 5.17-.46 7.83-4.45 1.8-2.69 3.64-3.35 4.63-3.51v1.21c0 .45.54.67.86.36Z" />
        <path d="M10.24 9.9c-2.25-2.48-4.5-2.87-5.61-2.87a1 1 0 0 0 0 2c.6 0 2.43.2 4.36 2.47l1.25-1.6Zm2.1 5.33 1.3-1.54a7.76 7.76 0 0 0 3.45 1.3v-1.27a.5.5 0 0 1 .86-.35l2.28 2.28c.2.2.2.51 0 .7l-2.28 2.29a.5.5 0 0 1-.86-.36V17a9.77 9.77 0 0 1-4.75-1.77Z" />
      </svg>
    </Icon>
  );
}
